import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

  <Layout title="Supply Chain">
    <section>
      <h3>Connect your supply chain to connect with your customer.</h3>
      <p>In order to deliver the right product to the right location, supply chain visibility and flexibility is key. Quantic can help you meet customer demands in the omni-channel world of today through supply chain connectivity.</p>

      <h3>Omni-channel engagement starts with supply.</h3>
      <p>In order to offer your customers the ability to purchase online and pick-up in store, or purchase in store and ship to home, or pick-up in another store location, retailers need a clear picture of their supply chain to be able to fulfill and meet customer demands. Quantic’s digital transformation experts can help you implement and integrate your supply chain so your customer is satisfied and engaged across all channels.</p>

      <div>TECHNOLOGY EXPERTISE</div>
      <ul>
        <li>Retail Store Inventory Management</li>
        <li>Retail Warehouse Management System</li>
        <li>Distributed Order Orchestration</li>
      </ul>

      <h3>View your store like never before with inventory management.</h3>
      <p>Store inventory management is a key component of the Quantic Retail Stores suite of software that when implemented by Quantic, in conjunction with Point of Service (Store or ORPOS), Back Office, and Central Office, delivers you an unmatched, all encompassing retail platform turbocharged to deliver you results. Quantic can quickly and easily integrate the store inventory management software into your store solution improving your overall supply chain visibility.</p>

      <h3>Warehouse management is critical to merchandising success.</h3>
      <p>When you implement the Quantic Retail Warehouse Management software alongside the Quantic Retail Merchandising platform, you improve shipping, receiving, and overall internal movement of merchandise across your partners and your stores. Quantic’s consultants can help you integrate these two systems giving you the supply chain insight you need to execute quickly and drive profitable growth.</p>
    </section>
  </Layout>
);

export default Page;
